import AuthService from "../../modules/auth/AuthService";
export default function auth({ next, router }) {
  console.log(
    !AuthService.check(),
    process.env.VUE_APP_ENABLE_LOGIN === "true"
  );
  if (!AuthService.check() && process.env.VUE_APP_ENABLE_LOGIN === "true") {
    return router.push({ name: "Login" });
  }
  return next();
}
