<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="840.000000pt"
    height="1060.000000pt"
    viewBox="0 0 840.000000 1060.000000"
    preserveAspectRatio="xMidYMid meet"
    class="cpd--badge"
  >
    <g
      transform="translate(0.000000,1060.000000) scale(0.100000,-0.100000)"
      :fill="$vuetify.theme.themes.light.secondary"
      stroke="none"
    >
      <path
        d="M4120 10194 c-59 -12 -133 -39 -245 -92 -66 -31 -122 -58 -124 -59
-2 -2 19 -52 48 -112 91 -193 168 -455 217 -738 l17 -103 237 0 237 0 7 38 c3
20 11 101 18 180 13 159 -1 476 -27 608 -24 127 -86 217 -175 257 -44 20 -156
31 -210 21z"
      />
      <path
        d="M3260 10074 c-30 -8 -89 -37 -130 -64 -97 -64 -224 -171 -228 -192
-2 -9 39 -72 91 -141 102 -134 183 -265 296 -480 87 -164 112 -197 152 -197
33 0 286 52 368 76 l53 15 -7 47 c-21 149 -87 421 -139 574 -54 158 -162 325
-237 363 -36 19 -146 18 -219 -1z"
      />
      <path
        d="M4797 10074 c-85 -15 -147 -38 -147 -53 0 -6 5 -33 11 -61 6 -28 16
-243 22 -477 l12 -427 205 -62 205 -62 25 28 c46 52 127 297 172 522 18 89 22
148 23 278 0 158 -1 167 -25 210 -46 82 -157 121 -340 119 -47 0 -120 -7 -163
-15z"
      />
      <path
        d="M5510 9722 c-28 -229 -92 -502 -172 -728 -28 -81 -47 -149 -42 -151
36 -12 237 -132 305 -182 l83 -61 28 33 c86 99 226 328 318 520 199 409 100
597 -340 647 -52 5 -111 12 -131 15 l-37 5 -12 -98z"
      />
      <path
        d="M2471 9760 c-71 -33 -139 -88 -196 -159 -70 -87 -145 -209 -145 -238
0 -17 18 -35 74 -72 99 -67 266 -222 459 -425 88 -91 164 -166 170 -166 6 0
70 33 142 74 71 41 155 84 185 96 62 25 72 35 63 70 -19 86 -176 383 -287 545
-78 114 -188 225 -256 260 -73 37 -151 43 -209 15z"
      />
      <path
        d="M6265 9313 c-30 -137 -183 -437 -357 -696 -44 -65 -78 -122 -76 -126
5 -15 313 -341 321 -341 11 0 165 141 297 271 315 311 415 490 349 625 -24 51
-124 146 -201 192 -73 43 -239 102 -288 102 -35 0 -40 -3 -45 -27z"
      />
      <path
        d="M1805 9248 c-74 -26 -165 -113 -212 -203 -42 -80 -103 -259 -103
-300 0 -32 2 -34 51 -44 106 -23 373 -161 623 -323 93 -60 172 -107 176 -106
15 6 325 302 328 313 6 23 -89 133 -250 293 -207 206 -313 293 -413 342 -103
50 -127 53 -200 28z"
      />
      <path
        d="M6852 8632 c-95 -147 -270 -333 -531 -563 l-64 -57 78 -148 c125
-241 143 -259 222 -234 54 18 197 91 315 161 324 192 479 354 466 488 -7 67
-39 129 -109 205 -69 75 -195 178 -266 218 l-48 26 -63 -96z"
      />
      <path
        d="M1232 8549 c-117 -59 -181 -220 -196 -496 -7 -119 -19 -108 124 -118
127 -9 352 -57 560 -120 102 -31 203 -58 225 -61 40 -6 41 -5 71 42 17 27 65
102 108 168 91 142 94 162 37 215 -53 49 -299 201 -446 276 -161 82 -239 107
-350 112 -78 4 -96 1 -133 -18z"
      />
      <path
        d="M7222 7830 c-150 -112 -298 -203 -517 -317 l-150 -78 2 -45 c1 -42
75 -376 91 -411 6 -15 20 -14 147 11 361 72 610 159 752 263 74 55 96 97 101
197 5 96 -21 178 -88 274 -66 95 -168 206 -188 206 -9 -1 -77 -45 -150 -100z"
      />
      <path
        d="M962 7769 c-70 -12 -103 -30 -146 -80 -57 -67 -71 -123 -70 -274 0
-97 6 -154 23 -225 12 -52 24 -96 26 -99 3 -2 28 1 57 8 30 6 234 16 463 22
226 5 423 13 439 17 26 7 30 15 51 98 13 49 42 141 64 203 23 63 41 119 41
125 0 15 -264 108 -410 146 -207 53 -433 78 -538 59z"
      />
      <path
        d="M7485 7033 c-148 -75 -487 -177 -729 -218 -49 -8 -90 -16 -91 -18 -2
-1 -6 -108 -9 -238 l-7 -236 53 -6 c180 -21 443 -29 567 -18 400 36 550 172
481 436 -24 93 -143 345 -162 344 -7 0 -53 -21 -103 -46z"
      />
      <path
        d="M1092 6969 c-244 -23 -377 -80 -426 -181 -50 -106 -41 -220 34 -411
44 -113 84 -177 111 -177 10 0 47 15 81 34 120 66 390 148 685 210 l158 33 3
235 2 236 -57 6 c-233 26 -431 31 -591 15z"
      />
      <path
        d="M1531 6264 c-425 -89 -688 -205 -768 -341 -24 -41 -28 -59 -27 -118
0 -58 6 -82 36 -145 51 -109 217 -320 252 -320 6 0 61 37 121 82 61 45 146
105 190 133 104 67 355 207 450 251 41 19 75 38 75 42 0 4 -14 52 -30 107 -23
76 -70 277 -70 300 0 2 -7 11 -16 19 -21 22 -75 19 -213 -10z"
      />
      <path
        d="M7470 6163 c-46 -14 -121 -16 -450 -18 l-395 -1 -33 -100 c-68 -206
-100 -329 -90 -341 14 -17 143 -65 300 -113 341 -102 587 -122 715 -56 59 29
76 48 103 109 43 98 33 392 -16 494 -23 45 -49 50 -134 26z"
      />
      <path
        d="M1870 5656 c-66 -25 -315 -154 -424 -220 -225 -137 -345 -254 -382
-370 -16 -50 -16 -56 1 -107 29 -87 54 -130 118 -196 84 -88 239 -193 285
-193 12 0 42 32 84 88 93 123 245 289 378 412 63 58 137 126 164 151 l50 46
-79 134 c-43 73 -91 162 -106 198 -15 37 -34 67 -41 68 -7 1 -29 -4 -48 -11z"
      />
      <path
        d="M6356 5452 c-25 -48 -77 -136 -116 -196 -38 -59 -70 -112 -70 -117 0
-5 64 -52 143 -103 352 -231 586 -336 751 -336 70 0 98 14 157 77 90 96 136
239 146 446 l6 117 -100 0 c-55 0 -140 7 -189 15 -146 25 -393 91 -525 139
-67 26 -131 46 -140 46 -12 0 -32 -28 -63 -88z"
      />
      <path
        d="M2179 5068 c-85 -67 -364 -345 -440 -438 -74 -91 -146 -221 -156
-282 -15 -99 30 -191 131 -262 94 -67 238 -135 327 -155 55 -13 72 5 115 119
61 167 169 364 324 595 39 58 73 112 76 120 6 15 -286 337 -310 343 -7 2 -37
-16 -67 -40z"
      />
      <path
        d="M5880 4841 l-162 -159 18 -34 c23 -46 445 -465 525 -521 220 -155
369 -141 499 47 65 95 140 280 140 345 0 32 -25 48 -117 76 -119 37 -291 127
-527 278 -109 70 -202 127 -207 127 -4 0 -81 -72 -169 -159z"
      />
      <path
        d="M2688 4653 c-36 -42 -180 -267 -241 -378 -202 -367 -232 -569 -102
-684 78 -68 190 -101 418 -124 l127 -13 1 46 c0 25 2 63 4 85 2 22 6 69 9 104
9 100 53 279 117 480 33 101 61 200 62 219 2 34 0 35 -163 141 -91 59 -177
115 -191 124 -24 16 -27 16 -41 0z"
      />
      <path
        d="M5425 4502 c-60 -32 -143 -77 -184 -101 -70 -41 -73 -44 -67 -74 8
-42 90 -211 166 -343 198 -347 338 -487 483 -487 62 0 111 20 179 70 85 62
244 257 273 335 5 12 -18 33 -92 86 -123 88 -223 179 -439 398 -104 106 -178
174 -190 174 -10 0 -68 -26 -129 -58z"
      />
      <path
        d="M3250 4308 c-26 -44 -93 -251 -141 -435 -114 -446 -58 -641 195 -684
84 -14 232 -4 336 22 99 25 105 33 91 99 -13 59 -30 486 -31 741 l0 166 -87
23 c-49 12 -143 40 -209 61 -67 22 -125 39 -128 39 -3 0 -15 -14 -26 -32z"
      />
      <path
        d="M4865 4263 c-66 -18 -160 -40 -209 -47 -96 -15 -116 -28 -116 -75 0
-43 68 -363 101 -472 81 -273 175 -425 294 -475 72 -30 204 -9 304 48 54 31
203 152 250 202 l24 25 -49 57 c-165 192 -230 290 -370 567 -58 114 -106 206
-107 206 -1 -1 -56 -17 -122 -36z"
      />
      <path
        d="M3874 4104 c-5 -44 -9 -200 -9 -349 0 -288 10 -378 57 -503 51 -138
104 -175 248 -174 134 1 297 53 427 135 l43 27 -26 58 c-96 212 -169 446 -224
712 -17 85 -34 157 -36 160 -3 2 -110 6 -239 8 l-233 4 -8 -78z"
      />
      <path
        d="M2310 3163 c-253 -687 -777 -2199 -765 -2211 8 -8 202 20 645 94
l444 75 316 -315 c173 -173 335 -331 359 -351 39 -32 46 -34 54 -20 13 24 78
202 277 760 389 1093 483 1366 473 1376 -6 6 -93 23 -194 39 -250 39 -394 72
-585 135 -320 105 -539 214 -853 425 -66 44 -124 80 -129 80 -5 0 -24 -39 -42
-87z"
      />
      <path
        d="M5969 3206 c-227 -160 -362 -238 -579 -334 -163 -72 -297 -123 -416
-157 -114 -33 -449 -100 -581 -116 -102 -13 -102 -13 -96 -38 15 -69 148 -473
268 -820 185 -534 464 -1323 470 -1330 3 -3 167 156 364 353 l358 358 419 -70
c486 -82 662 -107 672 -98 19 20 -764 2246 -806 2288 -6 6 -33 -8 -73 -36z"
      />
    </g>
    <text
      x="50%"
      y="42%"
      text-anchor="middle"
      alignment-baseline="middle"
      class="cpd--text"
    >
      {{ cpdPoints }} {{ cpd_caption }}
    </text>
  </svg>
</template>
<script>
export default {
  name: "CPDbadge",
  props: {
    cpdPoints: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cpd_caption() {
      return process.env.VUE_APP_CPD_CAPTION;
    },
  }
};
</script>
<style>
.cpd--badge {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--v-secondary-base);
  width: 90%;
  height: 90%;
}

.cpd--text {
  fill: var(--v-secondary-base);
  font-size: 9rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
